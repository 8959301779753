import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

// MUI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';

// Store
import * as orderAction from 'modules/order/order.action';
import * as playerAction from 'modules/player/player.action';
import * as snackbarAction from 'modules/snackbar/snackbar.action';
import {
  selectUpdatedTemplateForDisplay,
  selectTemplateById,
  selectDefaultTemplateId,
  selectFetchPending as selectFetchPendingTemplate,
} from 'modules/template/template.selector';
import {
  selectVoiceForDisplay,
  selectVoiceById,
  selectDefaultVoiceId,
  selectFetchPending as selectFetchPendingVoice,
} from 'modules/voice/voice.selector';
import {
  selectSoundForDisplay,
  selectSoundIdAccordingToAccess,
  selectSoundSourceAccordingToAccess,
} from 'modules/sound/sound.selector';
import {
  selectOrders,
  selectInprogressOrder,
  selectFetchPending as selectFetchPendingOrder,
} from 'modules/order/order.selector';
import {
  selectInfos,
  selectFetchPending as selectFetchPendingInfos,
} from 'modules/info/info.selector';
import { selectPlayerState } from 'modules/player/player.selector';

// Components
import TopBanner from 'modules/banner/components/topBanner.component';
import BottomBanner from 'modules/banner/components/bottomBanner.component';
import MultilingualCheckbox from 'modules/multilingual/components/checkbox.component';
// import Help from 'modules/help/components/help.component';
import InfoCard from 'modules/info/components/card.component';
import TemplateCard from 'modules/template/components/card.component';
import OrderTitleCard from 'modules/order/components/title/card.component';
import VoiceCard from 'modules/voice/components/card.component';
import PredefinedVoiceCard from 'modules/voice/components/cardPredefined.component';
import CustomVoiceCard from 'modules/voice/components/cardCustomVoice.component';
import SoundCard from 'modules/sound/components/card.component';
import PredefinedSoundCard from 'modules/sound/components/cardPredefined.component';
import NoSoundCard from 'modules/sound/components/cardNoSound.component';
import PlanningCard from 'modules/scheduler/components/card.component';
import Loader from 'modules/utils/components/loader.component';
import OutlinedPlayerButton from 'modules/player/components/button/outlined.component';
import MultilingualCard from 'modules/multilingual/components/multilingualCard.component';
import DialogDynamicVariables from 'modules/template/components/dialogDynamicVariables.component';

// Others
import { SoundSource } from 'modules/order/config/order.config';
import { Endpoint, getRouteMetaDataFromPathName } from 'router/routes.config';
import { PlayerStateEnum } from 'modules/player/config/player.config';
import {
  updateTemplateWithInfos,
  extractVariablesFromTemplate,
} from 'modules/template/helpers/textFormat.helpers';
import { MessageActions } from 'modules/message/config/message.config';

import { ValidUpdateOrder } from '../../../@types/order';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    content: {
      flex: 1,
      padding: `${115 + theme.spacing(2)}px ${theme.spacing(1)}px ${59 + theme.spacing(5)
        }px ${theme.spacing(1)}px`,
    },
    title: {
      marginTop: theme.spacing(1),
    },
    divider: {
      margin: `0 ${theme.spacing(3)}px ${theme.spacing(2)}px 0`,
    },
    card: {
      marginBottom: theme.spacing(2),
    },
    multilingual: {
      margin: theme.spacing(3),
    },
    help: {
      display: 'flex',
      justifyContent: 'center',
    },
    backdrop: {
      zIndex: 1201,
      color: 'rgba(0, 0, 0, 0.5)',
    },
    snackbar: {
      marginBottom: theme.spacing(5),
    },
    next: {
      color: theme.palette.common.white,
    },
    previous: {
      color: theme.palette.common.white,
    },
  })
);

export default function OrderCreate() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  // Store
  const inprogressOrder = useSelector(selectInprogressOrder);
  const updatedTemplate = useSelector(selectUpdatedTemplateForDisplay);
  const voice = useSelector(selectVoiceForDisplay);
  const sound = useSelector(selectSoundForDisplay);
  const fetchPendingOrder = useSelector(selectFetchPendingOrder);
  const fetchPendingTemplate = useSelector(selectFetchPendingTemplate);
  const fetchPendingVoice = useSelector(selectFetchPendingVoice);
  const fetchPendingInfos = useSelector(selectFetchPendingInfos);
  const infos = useSelector(selectInfos);
  const getTemplateById = useSelector(selectTemplateById);
  const getVoiceById = useSelector(selectVoiceById);
  const getSoundIdAccordingToAccess = useSelector(
    selectSoundIdAccordingToAccess
  );
  const getSoundSourceAccordingToAccess = useSelector(
    selectSoundSourceAccordingToAccess
  );
  const orders = useSelector(selectOrders);
  const playerState = useSelector(selectPlayerState);
  const defaultTemplateId = useSelector(selectDefaultTemplateId);
  const defaultVoiceId = useSelector(selectDefaultVoiceId);

  // State
  const [hasLineInfo, setHasLineInfo] = React.useState<boolean>(false);
  const [openDialogVariables, setOpenDialogVariables] =
    React.useState<boolean>(false);

  // Derived data
  const routeMetaData = getRouteMetaDataFromPathName(location.pathname);
  const hasOrder = orders && orders.length > 0;
  const defaultTemplate = getTemplateById(
    defaultTemplateId ? defaultTemplateId.toString() : '0'
  );

  const loading =
    fetchPendingOrder ||
    fetchPendingInfos ||
    fetchPendingTemplate ||
    fetchPendingVoice;

  const canSchedule =
    (infos && !infos.access?.all_granted && infos.access.disable_schedule) ||
    (infos && infos.access.all_granted);

  // Check if user has provide template dynamic variables
  const dynamicVariables = extractVariablesFromTemplate(
    inprogressOrder?.original_content
  );
  const dynamicVariableKeys = dynamicVariables.map((variable) => variable.key);
  const hasDynamicVariables = dynamicVariableKeys.length > 0;
  const hasEachDynamicVariableValues = dynamicVariableKeys.every((key) =>
    Object.keys(inprogressOrder?.dynamic_variables || {}).find(
      (valueKey) => valueKey === key
    )
  );

  const disabled = !hasLineInfo;

  const shouldUserAddVariableValues =
    hasDynamicVariables && !hasEachDynamicVariableValues;

  /**
   * Init template content in progress order
   */
  React.useEffect(() => {
    if (!infos) return;
    const { access } = infos;

    const needInit =
      inprogressOrder &&
      !inprogressOrder.template_id &&
      inprogressOrder.content.length === 0;

    // Init with template list
    if (access?.all_granted || access?.disable_template_restriction) {
      if (needInit && defaultTemplateId) {
        if (defaultTemplate) {
          dispatch(
            orderAction.saveTemplate({
              template_id: defaultTemplateId,
              content: updateTemplateWithInfos(defaultTemplate, infos),
              original_content: updateTemplateWithInfos(defaultTemplate, infos),
            })
          );
        }
      }
    } else {
      // Init with access info
      const accessTemplateId = access?.template_ids?.[0];
      const accessTemplate = getTemplateById(accessTemplateId?.toString());

      if (needInit && accessTemplate) {
        dispatch(
          orderAction.saveTemplate({
            template_id: accessTemplateId,
            content: updateTemplateWithInfos(accessTemplate, infos),
            original_content: updateTemplateWithInfos(accessTemplate, infos),
          })
        );
      }
    }
  }, [
    defaultTemplate,
    defaultTemplateId,
    dispatch,
    getTemplateById,
    infos,
    inprogressOrder,
  ]);

  /**
   * Init voice in progess order
   */
  React.useEffect(() => {
    if (!infos) return;

    const { access } = infos;

    const needInit =
      inprogressOrder &&
      !inprogressOrder.voice_id &&
      !inprogressOrder.custom_voice_url;

    // Init with voice list
    if (access.all_granted || access.disable_voice_restriction) {
      if (needInit && defaultVoiceId) {
        dispatch(
          orderAction.saveVoice({
            voice_id: defaultVoiceId,
            custom_voice_url: null,
          })
        );
      }
    } else {
      // Init with access info
      const accessVoiceId =
        access &&
        access?.[`voice_ids_${infos.gender ? infos.gender : 'male'}`]?.[0];
      if (needInit && accessVoiceId) {
        dispatch(
          orderAction.saveVoice({
            voice_id: accessVoiceId,
            custom_voice_url: null,
          })
        );
      }
    }
  }, [
    defaultVoiceId,
    dispatch,
    getTemplateById,
    getVoiceById,
    infos,
    inprogressOrder,
  ]);

  /**
   * Check require creterias for create an order
   */
  React.useEffect(() => {
    if (
      infos &&
      infos.last_name &&
      infos.first_name &&
      infos.company_name &&
      infos.phone_number
    ) {
      setHasLineInfo(true);
    } else {
      setHasLineInfo(false);
    }
  }, [infos, inprogressOrder]);

  async function handleOrderCreate() {
    if (shouldUserAddVariableValues) {
      return setOpenDialogVariables(true);
    }

    if (inprogressOrder && !inprogressOrder.title) {
      dispatch(
        snackbarAction.enqueueSnackbar({
          message: t('snackbar.error.title'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            action: (key: number) => (
              <Button
                style={{ color: 'white' }}
                onClick={() => dispatch(snackbarAction.closeSnackbar(key))}
              >
                {t('action.close')}
              </Button>
            ),
          },
        })
      );

      return;
    }

    if (!infos?.delay_before_install) return;

    if (playerState === PlayerStateEnum.PLAYING) {
      dispatch(playerAction.stop());
    }
    if (inprogressOrder && infos) {
      const order: ValidUpdateOrder = {
        phone_number: infos.phone_number,
        title: inprogressOrder.title as string,
        template_id: inprogressOrder.template_id,
        content: inprogressOrder.content,
        sound_id: inprogressOrder.sound_id,
        voice_id: inprogressOrder.voice_id as number,
        custom_voice_url: inprogressOrder.custom_voice_url,
        sound_source: inprogressOrder.sound_source,
        languages: inprogressOrder.languages,
        install_date: inprogressOrder.install_date,
        dynamic_variables: inprogressOrder.dynamic_variables,
      };

      // get soundId according to user selection and access
      if (inprogressOrder.sound_source === SoundSource.NO_SOUND) {
        order.sound_id = null;
      } else {
        order.sound_id = getSoundIdAccordingToAccess(order.sound_id);
        order.sound_source = getSoundSourceAccordingToAccess(
          order.sound_source
        );
      }

      await dispatch(
        orderAction.createOrder(
          order,
          infos.delay_before_install[MessageActions.CREATE_ORDER]
        )
      );
    }
  }

  function handlePrevious() {
    if (playerState === PlayerStateEnum.PLAYING) {
      dispatch(playerAction.stop());
      dispatch(orderAction.resetInProgressOrder());
    }
    history.push(Endpoint.HOME);
  }

  function handleVoiceSelection() {
    if (playerState === PlayerStateEnum.PLAYING) {
      dispatch(playerAction.stop());
    }
    history.push(Endpoint.VOICE_SELECTION);
  }

  function handleSoundSelection() {
    if (playerState === PlayerStateEnum.PLAYING) {
      dispatch(playerAction.stop());
    }
    history.push(Endpoint.SOUND_SELECTION);
  }

  function handleSchedulerNavigate() {
    history.push(
      `${Endpoint.SCHEDULER_SELECTION}?action=${MessageActions.CREATE_ORDER}`
    );
  }

  return (
    <div id="order-create-view" className={classes.container}>
      {openDialogVariables && (
        <DialogDynamicVariables
          open={openDialogVariables}
          onClose={() => setOpenDialogVariables(false)}
          selectedTemplateId={String(inprogressOrder?.template_id)}
        />
      )}
      <TopBanner
        title={routeMetaData && t(`${routeMetaData.name}`)}
        content={t('message.topBannerContent')}
      />
      <div id="content" className={classes.content}>
        {loading && (
          <Backdrop className={classes.backdrop} open>
            <Loader />
          </Backdrop>
        )}
        <section id="template">
          <Typography className={classes.title} variant="h6">
            {t('message.textChoice')}
          </Typography>
          <Divider className={classes.divider} variant="fullWidth" />
          <InfoCard classe={classes.card} />
          <OrderTitleCard classe={classes.card}>
            <Button onClick={() => history.push(Endpoint.ORDER_TITLE_UPDATE)}>
              {t('action.update')}
            </Button>
          </OrderTitleCard>

          {/* SELECTED TEMPLATE */}
          <TemplateCard
            title
            updatedTemplate={updatedTemplate}
            classe={classes.card}
            isCustomTemplate={!inprogressOrder?.template_id}
          >
            <Box display="flex" flexDirection="flexEnd">
              {hasDynamicVariables && (
                <Button
                  color="primary"
                  onClick={() => setOpenDialogVariables(true)}
                  fullWidth
                >
                  {t('dynamicVariables.add')}
                </Button>
              )}
              <Button onClick={() => history.push(Endpoint.TEMPLATE_SELECTION)}>
                {t('action.update')}
              </Button>
            </Box>
          </TemplateCard>
        </section>

        <section id="voice">
          <Typography className={classes.title} variant="h6">
            {t('message.voiceChoice')}
          </Typography>
          <Divider className={classes.divider} variant="fullWidth" />

          {/* CUSTOM VOICE */}
          {inprogressOrder && inprogressOrder.custom_voice_url && (
            <CustomVoiceCard classe={classes.card}>
              <>
                <OutlinedPlayerButton
                  id={inprogressOrder.custom_voice_url}
                  path={inprogressOrder.custom_voice_url}
                />
                <Button onClick={handleVoiceSelection}>
                  {t('action.update')}
                </Button>
              </>
            </CustomVoiceCard>
          )}

          {/* ACCESS VOICE */}
          {inprogressOrder &&
            !inprogressOrder.custom_voice_url &&
            !infos?.access?.all_granted &&
            !infos?.access.disable_voice_restriction && (
              <PredefinedVoiceCard classe={classes.card}>
                <>
                  {infos?.access.with_custom_voice && (
                    <Button onClick={handleVoiceSelection}>
                      {t('action.update')}
                    </Button>
                  )}
                </>
              </PredefinedVoiceCard>
            )}

          {/* SELECTED VOICE */}
          {inprogressOrder &&
            !inprogressOrder.custom_voice_url &&
            voice &&
            (infos?.access.all_granted ||
              infos?.access.disable_voice_restriction) && (
              <VoiceCard voice={voice} classe={classes.card}>
                <>
                  <OutlinedPlayerButton
                    id={voice.id.toString()}
                    path={voice.voice_url}
                  />
                  <Button onClick={handleVoiceSelection}>
                    {t('action.update')}
                  </Button>
                </>
              </VoiceCard>
            )}
        </section>

        <section id="sound">
          <Typography className={classes.title} variant="h6">
            {t('message.soundChoice')}
          </Typography>
          <Divider className={classes.divider} variant="fullWidth" />

          {/* SOUND CARD */}
          {infos &&
            sound &&
            infos.access &&
            (infos.access.all_granted === true ||
              infos.access.disable_sound_restriction) &&
            infos.access.sound_source &&
            infos.access.sound_source === SoundSource.LIBRARY &&
            inprogressOrder &&
            inprogressOrder.sound_source !== SoundSource.NO_SOUND && (
              <SoundCard sound={sound} classe={classes.card}>
                <>
                  <OutlinedPlayerButton
                    id={sound.id}
                    path={sound.file_30s ? sound.file_30s : ''}
                  />
                  <Button onClick={handleSoundSelection}>
                    {t('action.update')}
                  </Button>
                </>
              </SoundCard>
            )}

          {/* PREDEFINED SOUND CARD */}
          {infos &&
            infos.access &&
            infos.access.all_granted === false &&
            !infos.access.disable_sound_restriction &&
            infos.access.sound_source === SoundSource.LIBRARY && (
              <PredefinedSoundCard classe={classes.card} />
            )}

          {infos &&
            infos.access &&
            infos.access.sound_source &&
            (infos.access.sound_source === SoundSource.CUSTOM ||
              infos.access.sound_source === SoundSource.CUSTOM_ATS) && (
              <PredefinedSoundCard classe={classes.card} />
            )}

          {/* NO SOUND CARD RESTRICTION */}
          {infos &&
            infos.access &&
            infos.access.sound_source &&
            infos.access.sound_source === SoundSource.NO_SOUND && (
              <NoSoundCard classe={classes.card} />
            )}

          {/* NO SOUND CARD CHOOSE BY THE USER */}
          {inprogressOrder &&
            inprogressOrder.sound_source === SoundSource.NO_SOUND && (
              <NoSoundCard classe={classes.card}>
                <Button onClick={handleSoundSelection}>
                  {t('action.update')}
                </Button>
              </NoSoundCard>
            )}
        </section>

        <section id="scheduling">
          <Typography className={classes.title} variant="h6">
            {t('message.scheduling')}
          </Typography>
          <Divider className={classes.divider} variant="fullWidth" />
          <PlanningCard classe={classes.card}>
            <>
              {canSchedule && (
                <Button onClick={handleSchedulerNavigate}>
                  {t('action.update')}
                </Button>
              )}
            </>
          </PlanningCard>
        </section>

        <section id="other">
          {infos && infos.has_multilanguage && infos.access.all_granted && (
            <MultilingualCheckbox classe={classes.multilingual} />
          )}

          {infos && infos.has_multilanguage && !infos.access.all_granted && (
            <div>
              <Typography className={classes.title} variant="h6">
                {t('multilinguage', { count: infos.access.languages.length })}
              </Typography>
              <Divider className={classes.divider} variant="fullWidth" />

              <MultilingualCard languages={infos.access.languages} />
            </div>
          )}

          {/* <div className={classes.help}>
            <Help />
          </div> */}
        </section>
      </div>
      <BottomBanner
        Previous={(props: any) => (
          <Button
            onClick={handlePrevious}
            disabled={!hasOrder}
            classes={{ root: classes.previous }}
            {...props}
          >
            {t('action.previous')}
          </Button>
        )}
        Next={(props: any) => (
          <Button
            onClick={handleOrderCreate}
            disabled={disabled}
            classes={{ root: classes.next }}
            {...props}
          >
            {t('action.next')}
          </Button>
        )}
      />
    </div>
  );
}
