import { SoundQuery } from '../../@types/sound';
import { Action as orderAction } from 'redux';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { State } from '../../@types/state';
import { MapOrders, Order } from '../../@types/state/order';
import {
  ValidUpdatePhoneNumber,
  ValidUpdateTitle,
  ValidUpdateTemplate,
  ValidUpdateVoice,
  ValidUpdateSound,
  ValidUpdateOrder,
  sortInProgressMultilingual,
} from '../../@types/order';
import {
  CreateOrderPayload,
  CreateOrderPayloadMultilingual,
} from '../../@types/api/order';
import { IDelayBeforeInstall } from '../../@types/state/info';
import { OrderActionTypes } from './types';
import { Endpoint } from '../../router/routes.config';
import { waitFor } from '../../services/api/helpers/various.helpers';
import appConfig from '../../application/app.config';
import * as orderServiceApi from '../../services/api/order.service';
import * as templateAction from '../template/template.action';
import * as voiceAction from '../voice/voice.action';
import * as soundAction from '../sound/sound.action';
import * as snackbarAction from '../snackbar/snackbar.action';
import * as messageAction from 'modules/message/message.action';
import config from '../../application/app.config';
import { SoundSource } from 'modules/order/config/order.config';
import { addDays } from 'modules/utils/utils.helpers';

export type OrderAction =
  | SavePhoneNumber
  | SaveTitle
  | SaveTemplate
  | SaveTemplateDynamicVariables
  | SaveVoice
  | SaveSound
  | ResetInProgressOrder
  | CreateOrderPending
  | CreateOrderSuccess
  | CreateOrderError
  | CallHistoryMethodAction
  | GetOrdersPending
  | GetOrdersSuccess
  | GetOrdersError
  | snackbarAction.SnackbarAction
  | SetNoSound
  | ActivateMultilingual
  | SaveMultilingual
  | SetScheduler
  | CreateRemixPending
  | CreateRemixSuccess
  | CreateRemixError
  | CreateInstallPending
  | CreateInstallSuccess
  | CreateInstallError;

export interface SaveMultilingual
  extends orderAction<OrderActionTypes.SAVE_MULTILINGUAL> {
  payload: sortInProgressMultilingual[];
}

export const saveMultilingual = (payload: sortInProgressMultilingual[]) => {
  return {
    type: OrderActionTypes.SAVE_MULTILINGUAL,
    payload,
  };
};

export interface ActivateMultilingual
  extends orderAction<OrderActionTypes.ACTIVATE_MULTILINGUAL> {
  payload: boolean;
}

export const activateMultilingual = (payload: boolean) => {
  return {
    type: OrderActionTypes.ACTIVATE_MULTILINGUAL,
    payload,
  };
};

export interface SavePhoneNumber
  extends orderAction<OrderActionTypes.SAVE_PHONE_NUMBER> {
  payload: ValidUpdatePhoneNumber;
}

export const savePhoneNumber = (payload: ValidUpdatePhoneNumber) => {
  return {
    type: OrderActionTypes.SAVE_PHONE_NUMBER,
    payload,
  };
};

export interface SaveTitle extends orderAction<OrderActionTypes.SAVE_TITLE> {
  payload: ValidUpdateTitle;
}

export const saveTitle = (payload: ValidUpdateTitle) => {
  return {
    type: OrderActionTypes.SAVE_TITLE,
    payload,
  };
};

export interface SaveTemplate
  extends orderAction<OrderActionTypes.SAVE_TEMPLATE> {
  payload: ValidUpdateTemplate;
}

export const saveTemplate = (payload: ValidUpdateTemplate) => {
  return {
    type: OrderActionTypes.SAVE_TEMPLATE,
    payload,
  };
};

export interface SaveTemplateDynamicVariables
  extends orderAction<OrderActionTypes.SAVE_TEMPLATE_DYNAMIC_VARIABLES> {
  payload: { dynamic_variables: Record<string, string> };
}

export const saveTemplateDynamicVariables = (payload: {
  dynamic_variables: Record<string, string>;
}) => {
  return {
    type: OrderActionTypes.SAVE_TEMPLATE_DYNAMIC_VARIABLES,
    payload,
  };
};

export interface SaveVoice extends orderAction<OrderActionTypes.SAVE_VOICE> {
  payload: ValidUpdateVoice;
}

export const saveVoice = (payload: ValidUpdateVoice) => {
  return {
    type: OrderActionTypes.SAVE_VOICE,
    payload,
  };
};

export interface SaveSound extends orderAction<OrderActionTypes.SAVE_SOUND> {
  payload: ValidUpdateSound;
}

export const saveSound = (payload: ValidUpdateSound) => {
  return {
    type: OrderActionTypes.SAVE_SOUND,
    payload,
  };
};

export interface ResetInProgressOrder
  extends orderAction<OrderActionTypes.RESET_IN_PROGRESS_ORDER> { }

export const resetInProgressOrder = () => {
  return {
    type: OrderActionTypes.RESET_IN_PROGRESS_ORDER,
  };
};

export interface CreateOrderPending
  extends orderAction<OrderActionTypes.CREATE_ORDER_PENDING> {
  pending: boolean;
}

export interface CreateOrderSuccess
  extends orderAction<OrderActionTypes.CREATE_ORDER_SUCCESS> { }

export interface CreateOrderError
  extends orderAction<OrderActionTypes.CREATE_ORDER_ERROR> {
  error: any;
}

const createOrderPending = (pending: boolean): CreateOrderPending => {
  return {
    type: OrderActionTypes.CREATE_ORDER_PENDING,
    pending,
  };
};

const createOrderSuccess = (): CreateOrderSuccess => {
  return {
    type: OrderActionTypes.CREATE_ORDER_SUCCESS,
  };
};

const createOrderError = (error: any): CreateOrderError => {
  return {
    type: OrderActionTypes.CREATE_ORDER_ERROR,
    error,
  };
};

export const createOrder = (
  order: ValidUpdateOrder,
  delayBeforeInstall: IDelayBeforeInstall
): ThunkAction<Promise<void>, State, {}, OrderAction> => {
  return async (
    dispatch: ThunkDispatch<State, {}, OrderAction>
  ): Promise<void> => {
    dispatch(createOrderPending(true));

    try {
      let languages: CreateOrderPayloadMultilingual[] = [];

      // Calculate Open day if no day was selected with the scheduler

      if (!order.install_date) {
        const installDate = addDays(delayBeforeInstall);
        order.install_date = installDate.toISOString();
      }
      console.log('after', order);

      // Add default language in language key when multilingual is not selected
      // Remove content -> create by backend
      if (order.languages.length === 0) {
        languages.push({
          language: appConfig.defaultlanguage,
          voice_id: order.voice_id as number | null,
          template_id: order.template_id,
          content: order.content,
        });
      } else {
        for (const lang of order.languages) {
          languages.push({
            language: lang.language,
            voice_id: lang.voice_id as number | null,
            template_id: lang.template_id as number | null,
            content:
              lang.language === config.defaultlanguage
                ? order.content
                : (lang.content as string),
          });
        }
      }

      const payloadOrder: CreateOrderPayload = {
        phone_number: order.phone_number,
        title: order.title,
        template_id: order.template_id,
        content: order.content,
        sound_id: order.sound_id,
        voice_id: order.voice_id,
        custom_voice_url: order.custom_voice_url,
        sound_source: order.sound_source,
        languages: languages,
        install_date: order.install_date,
        dynamic_variables: order.dynamic_variables,
      };

      const response = await orderServiceApi.createOrder(payloadOrder);
      await waitFor(appConfig.waitForTime);

      if (response.status === 204) {
        if (response) {
          dispatch(createOrderSuccess());
          dispatch(getOrders());
          dispatch(push(Endpoint.ORDER_CREATE_SUCCESS));
        }
      }
    } catch (error) {
      const { data } = error?.response || {};

      dispatch(createOrderError(data));
    }
  };
};

export interface GetOrdersPending
  extends orderAction<OrderActionTypes.GET_ORDERS_PENDING> {
  pending: boolean;
}

export interface GetOrdersSuccess
  extends orderAction<OrderActionTypes.GET_ORDERS_SUCCESS> {
  data: State['order']['data']['orders'];
}

export interface GetOrdersError
  extends orderAction<OrderActionTypes.GET_ORDERS_ERROR> {
  message: any;
}

const getOrdersPending = (pending: boolean): GetOrdersPending => {
  return { type: OrderActionTypes.GET_ORDERS_PENDING, pending };
};

const getOrdersSuccess = (
  data: State['order']['data']['orders']
): GetOrdersSuccess => {
  return { type: OrderActionTypes.GET_ORDERS_SUCCESS, data };
};

const getOrdersError = (message: string): GetOrdersError => {
  return { type: OrderActionTypes.GET_ORDERS_ERROR, message };
};

export const getOrders = (): ThunkAction<
  Promise<void>,
  State,
  {},
  OrderAction
> => {
  return async (
    dispatch: ThunkDispatch<State, {}, OrderAction>
  ): Promise<void> => {
    dispatch(getOrdersPending(true));
    try {
      const response = await orderServiceApi.getOrders();

      if (response.status === 200) {
        const { data } = response;
        const normalizedData: MapOrders | null = data.reduce(
          (accumulator: MapOrders, currentValue: Order) => {
            accumulator[currentValue['id']] = currentValue;

            return accumulator;
          },
          {}
        );
        await dispatch(templateAction.getTemplates());
        await dispatch(voiceAction.getVoices());
        const soundQuery: SoundQuery = {
          query: '',
          tempo_min: 0,
          tempo_max: 5,
          offset: 0,
          size: parseInt(`${config.search.sound.limit}`),
          categories: null,
          types: null,
        };
        await dispatch(soundAction.getSounds(soundQuery));
        dispatch(getOrdersSuccess(normalizedData));
      }
    } catch (error) {
      dispatch(getOrdersError(error.message));
    }
  };
};

export interface SetNoSound
  extends orderAction<OrderActionTypes.SET_NO_SOUND> { }

export const setNoSound = (): SetNoSound => {
  return { type: OrderActionTypes.SET_NO_SOUND };
};

export interface SetScheduler
  extends orderAction<OrderActionTypes.SET_SCHEDULER> {
  payload: string | null;
}

export const setScheduler = (payload: string | null): SetScheduler => {
  return { type: OrderActionTypes.SET_SCHEDULER, payload };
};

export interface CreateRemixPending
  extends orderAction<OrderActionTypes.CREATE_REMIX_PENDING> {
  pending: boolean;
}

export interface CreateRemixSuccess
  extends orderAction<OrderActionTypes.CREATE_REMIX_SUCCESS> { }

export interface CreateRemixError
  extends orderAction<OrderActionTypes.CREATE_REMIX_ERROR> {
  error: any;
}

const createRemixPending = (pending: boolean): CreateRemixPending => {
  return {
    type: OrderActionTypes.CREATE_REMIX_PENDING,
    pending,
  };
};

const createRemixSuccess = (): CreateRemixSuccess => {
  return {
    type: OrderActionTypes.CREATE_REMIX_SUCCESS,
  };
};

const createRemixError = (error: any): CreateRemixError => {
  return {
    type: OrderActionTypes.CREATE_REMIX_ERROR,
    error,
  };
};

export interface ICreateRemix {
  order_id: number;
  sound_id: string | null;
  sound_source: SoundSource;
  install_date: string | null;
  delay_before_install: IDelayBeforeInstall;
}

export const createRemix = (
  payload: ICreateRemix
): ThunkAction<Promise<void>, State, {}, OrderAction> => {
  return async (
    dispatch: ThunkDispatch<State, {}, OrderAction>
  ): Promise<void> => {
    dispatch(createRemixPending(true));
    try {
      if (!payload.install_date) {
        // Calculate delay of the message install if no days was selected with the scheduler
        const installDate = addDays(payload.delay_before_install);
        payload.install_date = installDate.toISOString();
      }

      const apiPayload: orderServiceApi.ICreateRemixApi = {
        order_id: payload.order_id,
        sound_id: payload.sound_id,
        sound_source: payload.sound_source,
        install_date: payload.install_date,
      };

      const response = await orderServiceApi.createRemix(apiPayload);

      await waitFor(appConfig.waitForTime);

      if (response.status === 204) {
        if (response) {
          dispatch(createRemixSuccess());
          dispatch(push(Endpoint.ORDER_CREATE_SUCCESS));
        }
      } else {
        dispatch(
          createRemixError(`remix of order_id ${payload.order_id} failed`)
        );
        throw new Error('message.remixFailed');
      }
    } catch (error) {
      dispatch(createRemixError(error.message));
    }
  };
};

export interface CreateInstallPending
  extends orderAction<OrderActionTypes.CREATE_INSTALL_PENDING> {
  pending: boolean;
}

export interface CreateInstallSuccess
  extends orderAction<OrderActionTypes.CREATE_INSTALL_SUCCESS> { }

export interface CreateInstallError
  extends orderAction<OrderActionTypes.CREATE_INSTALL_ERROR> {
  error: any;
}

const createInstallPending = (pending: boolean): CreateInstallPending => {
  return {
    type: OrderActionTypes.CREATE_INSTALL_PENDING,
    pending,
  };
};

const createInstallSuccess = (): CreateInstallSuccess => {
  return {
    type: OrderActionTypes.CREATE_INSTALL_SUCCESS,
  };
};

const createInstallError = (error: any): CreateInstallError => {
  return {
    type: OrderActionTypes.CREATE_INSTALL_ERROR,
    error,
  };
};

export interface ICreateInstall {
  message_id: number;
  install_date: string | null;
  delay_before_install: IDelayBeforeInstall;
}

export const createInstall = (
  payload: ICreateInstall
): ThunkAction<Promise<void>, State, {}, OrderAction> => {
  return async (
    dispatch: ThunkDispatch<State, {}, OrderAction>
  ): Promise<void> => {
    dispatch(createInstallPending(true));
    try {
      if (!payload.install_date) {
        // Calculate delay of the message install if no days was selected with the scheduler
        const installDate = addDays(payload.delay_before_install);
        payload.install_date = installDate.toISOString();
      }

      const apiPayload: orderServiceApi.ICreateInstallApi = {
        message_id: payload.message_id,
        install_date: payload.install_date,
      };

      const response = await orderServiceApi.createInstall(apiPayload);

      await waitFor(appConfig.waitForTime);

      if (response.status === 204) {
        if (response) {
          dispatch(createInstallSuccess());
          dispatch(messageAction.getMessages());
          dispatch(push(Endpoint.ORDER_CREATE_SUCCESS));
        }
      } else {
        dispatch(
          createInstallError(`remix of message_id ${payload.message_id} failed`)
        );
        throw new Error('message.remixFailed');
      }
    } catch (error) {
      dispatch(createInstallError(error.message));
    }
  };
};
