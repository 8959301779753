export enum Role {
  ADMIN = 'ats_mobility_super_admin',
  USER = 'ats_mobility_user',
}

export interface RoleMapping extends Map<Role | null, React.ElementType> { }

const config = {
  roleMapping: {
    [Role.ADMIN]: 'admin_v0',
    [Role.USER]: 'user_v0',
  },
};

export default config;
