import React from 'react';
import Step1, { IProps as TypeStep1 } from '../components/content/step1';
import Step2 from '../components/content/step2';

export enum Steps {
  STEP_1 = 1,
  STEP_2 = 2,
}

export interface Step {
  id: number;
  Content: React.ElementType;
}

export interface Config {
  ndSteps: number;
  steps: Step[];
}

const config = {
  nbStep: 2,
  steps: [
    {
      id: 1,
      Content: (props: TypeStep1) => <Step1 {...props}></Step1>,
    },
    {
      id: 2,
      Content: () => <Step2></Step2>,
    },
  ],
};


export default config;
